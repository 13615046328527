<template>
    <div>
        <vs-table
            v-if="report && report.wills"
            :onlyClickCheckbox="true"
            ref="table"
            stripe
            :sst="false"
            search
            :data="report.wills"
        >
            <div slot="header" class="flex items-center flex-grow justify-end">
                <div class="flex mr-4 mb-4">
                    <div>
                        <DatePicker
                            style="z-index: 10000"
                            mode="range"
                            v-model="range"
                        ></DatePicker>
                    </div>
                </div>

                <change-time-duration-dropdown
                    :border="true"
                    @timeDurationChanged="setDuration"
                    :initialDays="initialDays"
                />
            </div>
            <template slot="thead">
                <vs-th sort-key="uploaded_at">Date Created</vs-th>
                <vs-th sort-key="user.personaldetails.full_name">Client</vs-th>
                <vs-th sort-key="coupon_used.code">Coupon Used</vs-th>
                <vs-th sort-key="log.coupon_used.quick_code_used"
                    >Access Code Used</vs-th
                >
                <vs-th sort-key="payment_details.will_amount"
                    >Amount Paid</vs-th
                >
                <vs-th
                >Actions</vs-th
                >
            </template>

            <template slot-scope="{ data }">
                <tbody>
                    <vs-tr
                        :data="log"
                        :key="index"
                        v-for="(log, index) in data"
                    >
                        <vs-td>
                            {{ formatDateAndTime(log.uploaded_at) }}
                        </vs-td>

                        <vs-td>
                            <p>
                                <span v-if="log.user.personaldetails">{{
                                    log.user.personaldetails.full_name
                                }}</span>
                                <small><br />{{ log.user.email }}</small>
                            </p>
                        </vs-td>

                        <vs-td>
                            <span v-if="log.coupon_used">{{
                                log.coupon_used.code
                            }}</span>
                        </vs-td>
                        <vs-td>
                            <span
                                v-if="
                                    log.coupon_used &&
                                    log.coupon_used.quick_code_used
                                "
                                >{{
                                    log.coupon_used.quick_code_used.code
                                }}</span
                            >
                            <small
                                v-if="
                                    log.coupon_used &&
                                    log.coupon_used.quick_code_used
                                "
                                ><br />{{
                                    log.coupon_used.quick_code_used.description
                                }}</small
                            >
                        </vs-td>
                        <vs-td
                            ><span
                                v-if="
                                    log.payment_details &&
                                    log.payment_details.will_amount
                                "
                            >
                                £{{ log.payment_details.will_amount / 100 }}
                            </span>
                        </vs-td>
                        <vs-td
                        >
                            <vx-tooltip
                                title='Download'
                                :text="'Will Completed Confirmation Letter'"
                                color="primary"
                            >
                                <feather-icon @click='downloadPDF(log.id)' icon="FileIcon"></feather-icon>
                            </vx-tooltip>
                        </vs-td>
                    </vs-tr>
                </tbody>
            </template>
        </vs-table>
        <div class="flex flex-row-reverse w-full mt-6 mb-6">
            <vs-list v-if="report" class="w-full md:w-1/2 lg:w-1/2">
                <vs-list-item v-if="report.quick_codes" class="mb-3">
                    <p class="mb-3 font-bold">Access Codes Used</p>
                    <div
                        class="text-right"
                        v-for="(log, index) in report.quick_codes"
                        :key="index"
                    >
                        <h6> {{
                                log.coupon_used__quick_code_used__code ||
                                'OTHER METHOD'
                            }}
                            :
                            {{ log.count }}
                        </h6>


                        <p v-for="(person, index) in log.will_ids"
                              :key="index + 'personName'">{{ person }}
                        </p>

                    </div>
                </vs-list-item>
                <vs-list-item class="font-bold mb-3"
                    >Total Wills : {{ report.wills.length }}
                </vs-list-item>

                <vs-list-item class="font-bold mb-3" style="margin-bottom: 200px"
                    >Total Will Payments : £{{
                        report.total_collected / 100
                    }}</vs-list-item
                >
            </vs-list>
        </div>
    </div>
</template>

<script>
import { http } from '@/services'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import { textFormat } from '@/mixins/textFormat'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    name: 'WillsReportTable',
    components: {
        ChangeTimeDurationDropdown,
        DatePicker,
    },
    mixins: [textFormat],
    mounted() {
        this.getReport(this.initialDays)
    },
    watch: {
        range: {
            immediate: true,
            deep: true,
            handler(valueNew, valueOld) {
                if (valueNew) this.getReport(null, valueNew.start, valueNew.end)
            },
        },
    },
    computed: {
        subtitle() {
            return 'Last 28 Days'
        },
    },
    data() {
        return {
            report: null,
            range: null,
            initialDays: 28,
        }
    },
    methods: {
        getReport(days = null, start = null, end = null) {
            let params = {
                days: days,
                start: start,
                end: end,
            }
            http.get('report_wills', {
                params: params,
            })
                .then((response) => {
                    this.report = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        setDuration(value) {
            this.range = null
            if (value === '365') this.getReport(365)
            if (value === '182') this.getReport(182)
            if (value === '28') this.getReport(28)
            if (value === '14') this.getReport(14)
            if (value === '7') this.getReport(7)
        },
        downloadPDF (willId) {
            http.post('will_completed_letter', {id: willId}).then(
                response => {
                    // const filename = `confirmation-letter-${willId}.pdf`
                    this.openFile(response)
                }
            ).catch(
                error => {
                    console.log(error)
                }
            )
        },
        openFile(response) {
            // const type = response.headers['content-type']
            // const blob = new Blob([response.data], {
            //     type: type,
            //     encoding: 'utf-8',
            // })
            // const link = document.createElement('a')
            // link.href = window.URL.createObjectURL(blob)
            // link.download = filename
            // link.click()
            window.open(response.data, '_blank')
        },

    },
}
</script>

<style>
.over {
    overflow: visible;
}
</style>
