<!-- =========================================================================================
    File Name: ChangeTimeDurationDropdown.vue
    Description: Change duration dropdown component
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<!-- :class="data.class" -->

<template>
    <!-- View/Duplicates -->

    <vs-dropdown
        vs-trigger-click
        class="cursor-pointer"
        :class="{ staticClass, 'mb-4 mr-4 items-per-page-handler': border }"
    >
        <div
            v-if="border"
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
        >
            <span class="mr-2"> Last {{ days }} days</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
        </div>

        <small v-else class="flex cursor-pointer">
            <span>Last {{ days }} days</span>
            <feather-icon
                icon="ChevronDownIcon"
                svgClasses="h-4 w-4"
                class="ml-1"
            />
        </small>

        <vs-dropdown-menu class="w-32">
            <vs-dropdown-item @click="timeDurationChanged('365')"
                >365 Days</vs-dropdown-item
            >
            <vs-dropdown-item @click="timeDurationChanged('182')"
                >182 Days</vs-dropdown-item
            >
            <vs-dropdown-item @click="timeDurationChanged('28')"
                >28 Days</vs-dropdown-item
            >
            <vs-dropdown-item @click="timeDurationChanged('14')"
                >14 Days</vs-dropdown-item
            >
            <vs-dropdown-item @click="timeDurationChanged('7')"
                >7 Days</vs-dropdown-item
            >
        </vs-dropdown-menu>
    </vs-dropdown>
</template>

<script>
export default {
    name: 'change-time-duration-dropdown',
    props: ['staticClass', 'initialDays', 'border'],
    watch: {
        initialDays: {
            immediate: true,
            handler(value) {
                if (value) this.days = value
            },
        },
    },
    data() {
        return {
            days: '28',
        }
    },
    methods: {
        timeDurationChanged(value) {
            this.$emit('timeDurationChanged', value)
            this.days = value
        },
    },
}
</script>
