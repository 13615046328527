<template>
    <div>
        <div id="data-list-list-view" class="data-list-container">
            <WillsReportTable />
        </div>
    </div>
</template>

<script>
import WillsReportTable from '@/views/main/Reports/CompletedWills/Table'

export default {
    name: 'Report',
    components: { WillsReportTable },
}
</script>
